export const Links = {
  MAIL_BRICK: 'mailto:hello@brick.tech',
  STORE: 'https://onelink.to/gs8tj3',
  APPLESS: 'https://go.brickapp.se',
  INSTAGRAM: 'https://www.instagram.com/brick.app/?hl=en',
  FACEBOOK: 'https://www.facebook.com/brickapp',
  LINKEDIN: 'https://www.linkedin.com/company/brick-technology/',
  MONDAY_STATIONS:
    'https://forms.monday.com/forms/abe754ee42889dafba6d8e3be9992910?r=use1',
  PRICING:
    'https://brick-web-assets.s3.eu-central-1.amazonaws.com/Brick-list-pricing.pdf',
  REVENUE:
    'https://docs.google.com/spreadsheets/u/1/d/e/2PACX-1vQ7ApNfwbLzBqeFMxYIRX89gS68O4h-M9TbVw6DZd2WBsbEYF1ylBm4mynaL8ER-mY_9Ce_M_gRQ8fa/pubhtml',
  PRESS: 'https://news.brick.tech/',
  PRIVACY: 'https://brick-web-assets.s3.eu-central-1.amazonaws.com/Privacy.pdf',
  ARTICLES: 'https://articles.brick.tech',
  PAYMENT: 'https://articles.brick.tech/how-do-i-get-paid-as-a-brick-partner',
  PARTNER:
    'https://articles.brick.tech/how-we-will-help-you-once-you-have-signed',
  YOUTUBE: {
    SIX_SLOT: 'https://www.youtube.com/watch?v=t6fPV-w2XoQ',
    TWELVE_SLOT: 'https://www.youtube.com/watch?v=dM1US9aurWs',
    RENT_WITH_APP: 'https://www.youtube.com/shorts/IREa_qm_zhU',
    RENT_WITH_APPLESS: 'https://www.youtube.com/shorts/8u5p3oAT3LU',
  },
  LEARN_MORE: {
    FIVE_STEPS:
      'https://articles.brick.tech/5-steps-to-get-started-with-brick-from-application-to-sign',
    STARTING_COST:
      'https://articles.brick.tech/how-much-will-it-cost-to-start-a-shared-power-bank-network',
    POWER_CONSUMPTION:
      'https://articles.brick.tech/station-power-consumption-and-cost-estimate',
    EARNING_EXPECTATION:
      'https://articles.brick.tech/what-can-i-expect-to-earn-as-a-network-partner',
    SIGNING_HELP:
      'https://articles.brick.tech/how-we-will-help-you-once-you-have-signed',
    PROMOTE_BUSINESS: 'https://articles.brick.tech/marketing',
    BOOST_BAR:
      'https://insights.brick.tech/boost-your-bar-with-powerbank-sharing-stations',
    IMPROVE_DWELL_TIME:
      'https://insights.brick.tech/e-commerce-and-the-impact-on-shopping-malls',
    PLACE_STATIONS:
      'https://articles.brick.tech/where-to-place-stations-in-your-venues',
    VENUE_AGREEMENT: 'https://articles.brick.tech/the-venue-agreement',
    CASE_BAR:
      'https://articles.brick.tech/case-study-placing-a-powerbank-station-in-a-bar',
    CASE_SHOPPING:
      'https://articles.brick.tech/placing-a-powerbank-station-in-a-shopping-mall',
  },
  // WISH_A_VENUE: 'https://iot.brick.tech/wishavenue',
} as const

// Don't forget leading /, otherwise links will break!
export const Paths = {
  GET_A_STATION: '/get-a-station',
  PARTNERSHIP: '/partnership',
  PARTNERSHIP_PRODUCTS: '/partnership/products',
  PARTNERSHIP_WHY: '/partnership/why',
  PARTNERSHIP_APPLY: '/partnership/apply',
  VENUE_ONBOARDING: '/venue/onboarding',
  VENUE_B2B: '/venue/b2b',
  THANK_YOU: '/thank-you',
  RENT: '/rent',
  RENT_PRICING: '/rent/pricing',
  BRICK_FOR_BRANDS: '/brick-for-brands',
  ABOUT: '/about',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  SUPPORT: '/support',
  PRIVACY_BRIEF: '/privacy/brief',
  PRIVACY_YOUR_DATA: '/privacy/your-data',
  PRIVACY_COOKIES: '/privacy/cookies',
  HOME: '/',
  SOLUTIONS: '/solutions/',
  SOLUTIONS_RESTAURANTS: '/solutions/restaurants',
  SOLUTIONS_AIRPORTS: '/solutions/airports',
  SOLUTIONS_BARS: '/solutions/bars',
  SOLUTIONS_MALLS: '/solutions/malls',
  SOLUTIONS_ARENAS: '/solutions/arenas',
  SOLUTIONS_VENUES: '/solutions/venues',
} as const

export const SolutionsSlugs = {
  RESTAURANTS: 'restaurants',
  BARS: 'bars',
  MALLS: 'malls',
  ARENAS: 'arenas',
  AIRPORTS: 'airports',
  VENUES: 'venues',
} as const

export type SolutionsSlug = typeof SolutionsSlugs[keyof typeof SolutionsSlugs]

export const Anchors = {
  GET_IN_TOUCH: 'get-in-touch',
  OUR_STATIONS: 'our-stations',
  B4B_FORM: 'b4b-form',
} as const

export const locales = {
  'sv-se': 'Svenska',
  en: 'English',
  'de-de': 'Deutsch',
  es: 'Español',
  'fr-fr': 'Français',
  'it-it': 'Italiano',
  'hu-hu': 'Magyar',
  'sr-rs': 'Srpski',
  'tr-tr': 'Türkçe',
  'hr-hr': 'Hrvatski',
  'ka-ge': 'ქართული',
  'pl-pl': 'Polski',
  'ro-ro': 'Română',
  'nl-nl': 'Nederlands',
  'sk-sk': 'Slovakian',
  'pt-pt': 'Português',
  'mk-mk': 'македонски',
} as const

export const EUROPE_COUNTRY_CODES = [
  'AL',
  'AD',
  'AM',
  'AT',
  'BY',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FO',
  'FR',
  'GE',
  'GE',
  'GI',
  'GR',
  'HU',
  'IS',
  'IE',
  'IM',
  'IT',
  'XK',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MK',
  'MD',
  'MC',
  'ME',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'TR',
  'UA',
  'GB',
  'VA',
]
